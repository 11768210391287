@use '@angular/material' as mat;

@mixin x-shop-header-custom($theme-config) {
  $theme: map-get($theme-config, theme);
  $shopHeaderBorder: darken(mat.get-color-from-palette(map-get($theme, accent), 50), 15);
  border-bottom: 1px solid $shopHeaderBorder;
}

@mixin x-brand-logo {
  height: 16px;
  @include media-breakpoint-down(lg) {
    height: 12px;
  }
}
